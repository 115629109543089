.btn-orange button {
    background-color: #F79320 !important;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange button:hover {
    background-color: #F3A859 !important;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange:focus {
    background-color: #F3A859 !important;
    color: white;
}