.ant-radio-group-large .ant-radio-button-wrapper {
    height: 100%;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: rgb(0, 113, 185, .1);
    border: 1px solid #0071B9;
}

.rebate-card-row {
    width: 100%;
}

.rebate-card-confirm {
    width: 100%;
    margin-top: 20px;
}

.reward-card-confirm {
    min-height: 160px;
    margin-top: 20px;
}

.sample-img {
    padding: 10px;
}

.tooltip-icon {
    position: absolute;
    color: #0071B9;
    font-weight: bold;
    border: 1px solid #0071B9;
    left: 90px;
    top: -35px;
}

.tooltip-icon:hover {
    color: #404040;
    border: 1px solid #404040;
}

.ant-tooltip-inner {
    text-align: center;
}

.input-w-100 {
    width: 100%;
}

.ant-collapse-header {
    font-weight: 600;
}

.ant-collapse-header:hover {
    background-color: #F0F0F0;
}

.ant-collapse-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-color: #F0F0F0;
}

.ant-collapse-content-box p {
    padding-top: 10px;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}

/*
.finish-form {
    background-color: rgb(0, 113, 185, .1);
    border-radius: 4px;
}
*/