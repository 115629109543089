@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,400italic);
body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  */
  font-family: 'Figtree'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.logo {
    width: 180px;
}

.nav-links:hover {
    background-color: white;
    color: #F79320 !important;
    text-decoration: underline;
}

.nav-links:active {
    background-color: white;
    color: #F79320 !important;
    text-decoration: underline;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    background-color: white;
    color: #F79320 !important;
    text-decoration: underline;
}
.bg-blue {
    background-color: #0071B9;
}

.visa-card {
    position: absolute;
    margin-left: -10%;
    margin-top: .5%;
}
.btn-orange {
    background-color: #F79320;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange:hover {
    background-color: #F3A859;
    color: white;
}

.btn-orange:focus {
    background-color: #F3A859;
    color: white;
}

.white-link, .white-link:hover {
    color: white;
    text-decoration: underline;
}

.tooltip-icons {
    color: #0071B9;
    font-weight: bold;
    border: 1px solid #0071B9;
}

.tooltip-icons:hover {
    color: #404040;
    border: 1px solid #404040;
}

.stacked-border {
    border: 1px solid #E6E6E6;
    height: .5px;
    width: auto;
    margin-top: 20%;
}

.blue-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .blue-tooltip .blue-tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -150px;
    opacity: 0;
    transition: ease-in-out .2s;
  }
  
  .blue-tooltip .blue-tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
  
  .blue-tooltip:hover .blue-tooltiptext {
    visibility: visible;
    opacity: 1;
  }
.footer {
    color: white;
    background-color: #231F20;
}
.divider-orange {
    border: 2px solid #F79320;
    width: 40px;
}
.bg-gray {
    background-color: #707272 ;
}
.ant-collapse-header {
    font-weight: 600;
}

.ant-collapse-header:hover {
    background-color: #F0F0F0;
}

.ant-collapse-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-color: #F0F0F0;
}

.ant-collapse-content-box p {
    padding-top: 10px;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}
.btn-orange {
    background-color: #F79320;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange:hover {
    background-color: #F3A859;
    color: white;
}

.btn-orange:focus {
    background-color: #F3A859;
    color: white;
}
.ant-radio-group-large .ant-radio-button-wrapper {
    height: 100%;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: rgb(0, 113, 185, .1);
    border: 1px solid #0071B9;
}

.rebate-card-row {
    width: 100%;
}

.rebate-card {
    width: 100%;
    margin-top: 20px;
    min-height: 430px;
}

.rebate-card:hover {
    background-color: rgb(0, 113, 185, .1);
}

.reward-card {
    min-height: 160px;
    margin-top: 20px;
}

.reward-card:hover {
    background-color: rgb(0, 113, 185, .1);
}

.sample-img {
    padding: 10px;
}

.tooltip-icon {
    position: absolute;
    color: #0071B9;
    font-weight: bold;
    border: 1px solid #0071B9;
    left: 90px;
    top: -35px;
}

.tooltip-icon:hover {
    color: #404040;
    border: 1px solid #404040;
}

.ant-tooltip-inner {
    text-align: center;
}

.input-w-100 {
    width: 100%;
}

.ant-collapse-header {
    font-weight: 600;
}

.ant-collapse-header:hover {
    background-color: #F0F0F0;
}

.ant-collapse-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-color: #F0F0F0;
}

.ant-collapse-content-box p {
    padding-top: 10px;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}

/*
.finish-form {
    background-color: rgb(0, 113, 185, .1);
    border-radius: 4px;
}
*/
.Collapsible {
    background-color: white; 
    border-bottom: 1px solid #d9d9d9;
    padding-top: 10px;
    padding-bottom: 10px;
}
  
  .Collapsible__contentInner {
    padding: 10px;
    /*border: 1px solid #ebebeb;*/
    border-top: 0; }
    .Collapsible__contentInner p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px; }
      .Collapsible__contentInner p:last-child {
        margin-bottom: 0; }
  
  .Collapsible__trigger {
    display: block;
    font-weight: 500;
    font-size: 20px;
    text-decoration: none;
    color: #333333;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    
    color: black; }
    .Collapsible__trigger svg {
        font-size: 12px; 
    }
    .Collapsible__trigger:after {
      font-family: 'FontAwesome';
      /*content: '\f107';*/
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
     
      transition: -webkit-transform 300ms;
     
      transition: transform 300ms;
     
      transition: transform 300ms, -webkit-transform 300ms; }
      .Collapsible__trigger.is-open {
        background: #F0F0F0;
      }
    .Collapsible__trigger.is-open svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    /*.Collapsible__trigger.is-open:after {
      transform: rotateZ(180deg); }*/
    .Collapsible__trigger.is-disabled {
      opacity: 0.5;
      background-color: grey; }
  
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease; }
  
  .CustomTriggerCSS--open {
    background-color: darkslateblue; }
  
  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black; }

.ant-input[disabled], .ant-input[disabled],  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker-input > input[disabled] {
    color: black !important;
}

.highlight-class
{
    border: 1px solid #eb516d !important;
}

/*.ant-calendar-input-wrap {
    display: none;
}*/
.popup-style .ant-modal-close-x {
    display: none !important;
}
.ant-radio-group-large .ant-radio-button-wrapper {
    height: 100%;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: rgb(0, 113, 185, .1);
    border: 1px solid #0071B9;
}

.rebate-card-row {
    width: 100%;
}

.rebate-card-confirm {
    width: 100%;
    margin-top: 20px;
}

.reward-card-confirm {
    min-height: 160px;
    margin-top: 20px;
}

.sample-img {
    padding: 10px;
}

.tooltip-icon {
    position: absolute;
    color: #0071B9;
    font-weight: bold;
    border: 1px solid #0071B9;
    left: 90px;
    top: -35px;
}

.tooltip-icon:hover {
    color: #404040;
    border: 1px solid #404040;
}

.ant-tooltip-inner {
    text-align: center;
}

.input-w-100 {
    width: 100%;
}

.ant-collapse-header {
    font-weight: 600;
}

.ant-collapse-header:hover {
    background-color: #F0F0F0;
}

.ant-collapse-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-color: #F0F0F0;
}

.ant-collapse-content-box p {
    padding-top: 10px;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}

/*
.finish-form {
    background-color: rgb(0, 113, 185, .1);
    border-radius: 4px;
}
*/
.btn-orange button {
    background-color: #F79320 !important;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange button:hover {
    background-color: #F3A859 !important;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange:focus {
    background-color: #F3A859 !important;
    color: white;
}
table {
    text-align: left;
}
.ant-radio-group-large .ant-radio-button-wrapper {
    height: 100%;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: rgb(0, 113, 185, .1);
    border: 1px solid #0071B9;
}

.rebate-card-row {
    width: 100%;
}

.rebate-card {
    width: 100%;
    margin-top: 20px;
    min-height: 430px;
}

.rebate-card:hover {
    background-color: rgb(0, 113, 185, .1);
}

.reward-card {
    min-height: 160px;
    margin-top: 20px;
}

.reward-card:hover {
    background-color: rgb(0, 113, 185, .1);
}

.sample-img {
    padding: 10px;
}

.tooltip-icon {
    position: absolute;
    color: #0071B9;
    font-weight: bold;
    border: 1px solid #0071B9;
    left: 90px;
    top: -35px;
}

.tooltip-icon:hover {
    color: #404040;
    border: 1px solid #404040;
}

.ant-tooltip-inner {
    text-align: center;
}

.input-w-100 {
    width: 100%;
}

.ant-collapse-header {
    font-weight: 600;
}

.ant-collapse-header:hover {
    background-color: #F0F0F0;
}

.ant-collapse-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-color: #F0F0F0;
}

.ant-collapse-content-box p {
    padding-top: 10px;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}

/*
.finish-form {
    background-color: rgb(0, 113, 185, .1);
    border-radius: 4px;
}
*/

.Collapsible {
    background-color: white; 
    border-bottom: 1px solid #d9d9d9;
    padding-top: 10px;
    padding-bottom: 10px;
}
  
  .Collapsible__contentInner {
    padding: 10px;
    /*border: 1px solid #ebebeb;*/
    border-top: 0; }
    .Collapsible__contentInner p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px; }
      .Collapsible__contentInner p:last-child {
        margin-bottom: 0; }
  
  .Collapsible__trigger {
    display: block;
    font-weight: 500;
    font-size: 20px;
    text-decoration: none;
    color: #333333;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    
    color: black; }
    .Collapsible__trigger svg {
        font-size: 12px; 
    }
    .Collapsible__trigger:after {
      font-family: 'FontAwesome';
      /*content: '\f107';*/
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
     
      transition: -webkit-transform 300ms;
     
      transition: transform 300ms;
     
      transition: transform 300ms, -webkit-transform 300ms; }
      .Collapsible__trigger.is-open {
        background: #F0F0F0;
      }
    .Collapsible__trigger.is-open svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    /*.Collapsible__trigger.is-open:after {
      transform: rotateZ(180deg); }*/
    .Collapsible__trigger.is-disabled {
      opacity: 0.5;
      background-color: grey; }
  
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease; }
  
  .CustomTriggerCSS--open {
    background-color: darkslateblue; }
  
  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black; }

.ant-input[disabled], .ant-input[disabled],  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker-input > input[disabled] {
    color: black !important;
}
.min-vh-60 {
    min-height: 60vh;
}

.logo {
    width: 200px;
}
.btn-orange {
    background-color: #F79320;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange:hover {
    background-color: #F3A859;
    color: white;
}

.btn-orange:focus {
    background-color: #F3A859;
    color: white;
}

.stacked-border {
    border: 1px solid #E6E6E6;
    height: .5px;
    width: auto;
    margin-top: 20%;
}
