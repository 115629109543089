.btn-orange {
    background-color: #F79320;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange:hover {
    background-color: #F3A859;
    color: white;
}

.btn-orange:focus {
    background-color: #F3A859;
    color: white;
}

.stacked-border {
    border: 1px solid #E6E6E6;
    height: .5px;
    width: auto;
    margin-top: 20%;
}