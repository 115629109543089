.logo {
    width: 180px;
}

.nav-links:hover {
    background-color: white;
    color: #F79320 !important;
    text-decoration: underline;
}

.nav-links:active {
    background-color: white;
    color: #F79320 !important;
    text-decoration: underline;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    background-color: white;
    color: #F79320 !important;
    text-decoration: underline;
}