.ant-radio-group-large .ant-radio-button-wrapper {
    height: 100%;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background-color: rgb(0, 113, 185, .1);
    border: 1px solid #0071B9;
}

.rebate-card-row {
    width: 100%;
}

.rebate-card {
    width: 100%;
    margin-top: 20px;
    min-height: 430px;
}

.rebate-card:hover {
    background-color: rgb(0, 113, 185, .1);
}

.reward-card {
    min-height: 160px;
    margin-top: 20px;
}

.reward-card:hover {
    background-color: rgb(0, 113, 185, .1);
}

.sample-img {
    padding: 10px;
}

.tooltip-icon {
    position: absolute;
    color: #0071B9;
    font-weight: bold;
    border: 1px solid #0071B9;
    left: 90px;
    top: -35px;
}

.tooltip-icon:hover {
    color: #404040;
    border: 1px solid #404040;
}

.ant-tooltip-inner {
    text-align: center;
}

.input-w-100 {
    width: 100%;
}

.ant-collapse-header {
    font-weight: 600;
}

.ant-collapse-header:hover {
    background-color: #F0F0F0;
}

.ant-collapse-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ant-collapse-item-active .ant-collapse-header {
    background-color: #F0F0F0;
}

.ant-collapse-content-box p {
    padding-top: 10px;
}

.blue-link {
    color: #0071B9;
    text-decoration: underline;
}

.blue-link:hover {
    color: #F79320;
    text-decoration: underline;
}

/*
.finish-form {
    background-color: rgb(0, 113, 185, .1);
    border-radius: 4px;
}
*/

.Collapsible {
    background-color: white; 
    border-bottom: 1px solid #d9d9d9;
    padding-top: 10px;
    padding-bottom: 10px;
}
  
  .Collapsible__contentInner {
    padding: 10px;
    /*border: 1px solid #ebebeb;*/
    border-top: 0; }
    .Collapsible__contentInner p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px; }
      .Collapsible__contentInner p:last-child {
        margin-bottom: 0; }
  
  .Collapsible__trigger {
    display: block;
    font-weight: 500;
    font-size: 20px;
    text-decoration: none;
    color: #333333;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    
    color: black; }
    .Collapsible__trigger svg {
        font-size: 12px; 
    }
    .Collapsible__trigger:after {
      font-family: 'FontAwesome';
      /*content: '\f107';*/
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
     
      transition: transform 300ms; }
      .Collapsible__trigger.is-open {
        background: #F0F0F0;
      }
    .Collapsible__trigger.is-open svg {
        transform: rotate(180deg);
    }
    /*.Collapsible__trigger.is-open:after {
      transform: rotateZ(180deg); }*/
    .Collapsible__trigger.is-disabled {
      opacity: 0.5;
      background-color: grey; }
  
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease; }
  
  .CustomTriggerCSS--open {
    background-color: darkslateblue; }
  
  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black; }

.ant-input[disabled], .ant-input[disabled],  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker-input > input[disabled] {
    color: black !important;
}