.btn-orange {
    background-color: #F79320;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
}

.btn-orange:hover {
    background-color: #F3A859;
    color: white;
}

.btn-orange:focus {
    background-color: #F3A859;
    color: white;
}

.white-link, .white-link:hover {
    color: white;
    text-decoration: underline;
}

.tooltip-icons {
    color: #0071B9;
    font-weight: bold;
    border: 1px solid #0071B9;
}

.tooltip-icons:hover {
    color: #404040;
    border: 1px solid #404040;
}

.stacked-border {
    border: 1px solid #E6E6E6;
    height: .5px;
    width: auto;
    margin-top: 20%;
}

.blue-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .blue-tooltip .blue-tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -150px;
    opacity: 0;
    transition: ease-in-out .2s;
  }
  
  .blue-tooltip .blue-tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
  }
  
  .blue-tooltip:hover .blue-tooltiptext {
    visibility: visible;
    opacity: 1;
  }